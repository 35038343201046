<template>
  <div>
    <!-- float button add -->
    <div class="btn-scroll-to-top">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        v-b-toggle.sidebar-right
        variant="primary"
        size="lg"
        class="btn-icon rounded-circle"
      >
        <feather-icon
          icon="PlusIcon"
          size="16"
        />
      </b-button>
    </div>
    <b-alert
      v-height-fade
      show
      dismissible
      fade
      class="mb-2"
      variant="primary"
    >
      <div class="alert-body">
        <span>Klik tombol + untuk melakukan pengajuan ijin sakit !</span>
      </div>
    </b-alert>
    <b-card
      title="Pencarian Data Ijin Sakit Karyawan"
    >
      <b-row>
        <b-col md="12">
          <b-row>
            <b-col
              md="2"
              sm="12"
            >
              <b-form-group>
                <v-select
                  v-model="statusReff"
                  :disabled="isSpinner"
                  placeholder="Pilih Status"
                  label="statusName"
                  :options="status"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="2"
              sm="12"
            >
              <b-form-group>
                <v-select
                  v-model="month_periode"
                  :disabled="isSpinner"
                  placeholder="Pilih Bulan"
                  label="monthName"
                  :options="monthPeriode"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="2"
              sm="12"
            >
              <b-form-group>
                <v-select
                  v-model="year_periode"
                  :disabled="isSpinner"
                  placeholder="Pilih Tahun"
                  label="year"
                  :options="yearPeriode"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="3"
              sm="12"
            >
                <b-form-group>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    block
                    @click="getSickLeavesEmployee()"
                  >
                    <b-spinner
                      v-show="isSpinner"
                      class="ml-auto"
                      :hidden="!isSpinner"
                      small
                      type="grow"
                    />
                    <div :hidden="isSpinner">
                      <feather-icon
                        icon="SearchIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">Search</span>
                    </div>
                  </b-button>
                </b-form-group>
            </b-col>
            <b-col
              md="3"
              sm="12"  
            >
                <b-form-group>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    block
                    @click="resetFilter()"
                  >
                    Reset
                  </b-button>
                </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>

    <b-row v-show="isTableShow">
      <b-col
        v-if="sickleaves.length"
        md="12"
        sm="12"
      >
        <b-card>
          <b-row>
            <b-col :hidden="isSpinner">
              <b-table
                ref="selectableTable"
                selectable
                select-mode="single"
                responsive="sm"
                :items="sickleaves"
                :fields="fields"
                @row-selected="onRowSelected"
              >
                <template #cell(No)="data">
                  {{ data.index + 1 }}
                </template>

                <template #cell(status)="data">
                  <b-badge :variant="statusName[1][data.value]">
                    {{ statusName[0][data.value] }}
                  </b-badge>
                </template>

                <template #cell(periode_start)="data">
                  {{ data.item.periode_start | moment("DD-MM-YYYY")}}
                </template>

                <template #cell(periode_end)="data">
                  {{ data.item.periode_end | moment("DD-MM-YYYY")}}
                </template>

                <template #cell(created_at)="data">
                  {{ data.item.created_at | moment("from", "now") }}
                </template>

                <template #cell(updated_at)="data">
                  {{ data.item.updated_at | moment("from", "now") }}
                </template>
              </b-table>

            </b-col>
          </b-row>
          <!-- tools button -->
          <b-row v-if="isLoadingHideButton">
            <b-col
              md="2"
              sm="12"
            >
              <b-form-group>
                <router-link
                  :to="{
                      name: 'updatesickleaves',
                      params: { _id: SickLeavesCode },
                    }"
                >
                  <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  block
                  :disabled="isDisableByVerified"
                  >
                      Edit
                  </b-button>
                </router-link>
              </b-form-group>
            </b-col>
            <b-col
              md="2"
              sm="12"
            >
              <b-form-group>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                block
                :disabled="isDisableByVerified"
                @click="confirmText()"
              >
                Batal
              </b-button>
              </b-form-group>
            </b-col>
            <b-col
              md="2"
              sm="12"
            >
              <b-form-group>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="warning"
                block
                :disabled="isDisableByNotSelected"
                @click="viewSelected()"
              >
                View
              </b-button>
              </b-form-group>
            </b-col>
            <b-col
              md="2"
              sm="12"
            >
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                block
                :disabled="isDisableByVerified"
                @click="resetSelected()"
              >
                Reset
              </b-button>
            </b-col>
          </b-row>
          <!-- tools button -->
          <div
            v-if="isSpinner"
            class="d-flex align-items-center"
          >
            <strong>Loading...</strong>
            <b-spinner
              small
              class="ml-auto"
            />
          </div>
        </b-card>
      </b-col>
      <b-col
        v-else
        md="12"
      >
        <b-card>
          <b-row>
            <b-col :hidden="isSpinner">
              Data tidak ditemukan
            </b-col>
          </b-row>
          <div
            v-if="isSpinner"
            class="d-flex align-items-center"
          >
            <strong>Loading...</strong>
            <b-spinner
              small
              class="ml-auto"
            />
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      ref="v-modal"
      ok-variant="danger"
      modal-class="modal-danger"
      hide-footer
      centered
      title="Peringatan !"
    >
      <b-card-text>
        test
      </b-card-text>
    </b-modal>

    <!-- cuti sidebar -->
    <add-sick-sidebar/>
    <!-- <update-cuti-sidebar :LeavesCode="LeavesCode" /> -->
  </div>
</template>

<script>
import {
  BAlert, BModal, BBadge, BCard, BCardTitle, BCardText, BRow, BCol, BFormGroup, BButton, BSpinner, BOverlay, BTable, BPagination, BInputGroup, BFormSelect, BFormInput, BInputGroupAppend, BDropdown, BDropdownItem, VBToggle,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { heightFade } from '@core/directives/animations'
import axios from 'axios'
import AddSickSidebar from './AddSickSidebar.vue'

export default {
  components: {
    BAlert,
    BModal,
    BBadge,
    BCard,
    BCardTitle,
    BCardText,
    vSelect,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BSpinner,
    BOverlay,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BTable,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    AddSickSidebar,
  },
  directives: {
    Ripple,
    heightFade,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      isTableShow: false,
      isSpinner: false,
      isLoadingHideButton: true,
      isDisableByNotSelected: true,
      isDisableByVerified: true,
      status: [],
      year_periode: [],
      month_periode: [],
      statusReff: [],
      monthPeriode: [
        {
          monthCode: '01',
          monthName: 'Januari',
        },
        {
          monthCode: '02',
          monthName: 'Februari',
        },
        {
          monthCode: '03',
          monthName: 'Maret',
        },
        {
          monthCode: '04',
          monthName: 'April',
        },
        {
          monthCode: '05',
          monthName: 'Mei',
        },
        {
          monthCode: '06',
          monthName: 'Juni',
        },
        {
          monthCode: '07',
          monthName: 'Juli',
        },
        {
          monthCode: '08',
          monthName: 'Agustus',
        },
        {
          monthCode: '09',
          monthName: 'September',
        },
        {
          monthCode: '10',
          monthName: 'Oktober',
        },
        {
          monthCode: '11',
          monthName: 'November',
        },
        {
          monthCode: '12',
          monthName: 'Desember',
        },
      ],
      yearPeriode: [
        {
          yearCode: '2022',
          year: '2022',
        },
        {
          yearCode: '2023',
          year: '2023',
        },
      ],
      employeeData: JSON.parse(localStorage.getItem('userData')),
      sickleaves: [],
      fields: [
        'No',
        {
          key: 'sick_leave_code',
          sortable: true,
          label: 'Kode Cuti',
        },
        {
          key: 'status',
          label: 'status',
        },
        {
          key: 'periode_start',
          label: 'Periode Awal',
        },
        {
          key: 'periode_end',
          label: 'Periode Akhir',
        },
        {
          key: 'employee_notes',
          label: 'Catatan',
        },
        {
          key: 'created_at',
          label: 'Dibuat Tanggal',
        },
        {
          key: 'updated_at',
          label: 'Diperiksa Tanggal',
        }
        // 'actions'
      ],
      statusName: [{
        'SLV-00': 'PROPOSED', 'SLV-01': 'APPROVED', 'SLV-02': 'VERIFIED', 'SLV-03': 'CANCELED', 'SLV-04': 'REJECTED',
      },
      {
        'SLV-00': 'light-warning', 'SLV-01': 'light-info', 'SLV-02': 'success', 'SLV-03': 'light-secondary', 'SLV-04': 'light-danger', 
      }],
      selected: [],
      SickLeavesCode:[],
    }
  },
  mounted() {
    this.getSickLeavesEmployee()
    this.getSickLeavesStatusReff()
    this.intervalFetchData()
  },
  methods: {
    resetFilter(){
      this.status = []
      this.month_periode = []
      this.year_periode = []
    },
    resetSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    getSickLeavesStatusReff() {
      axios.get('/sickLeaves/statusReff').then(response => {
        console.log(response.data)
        this.status = response.data.data
      })
    },
    intervalFetchData() {
      setInterval(() => {    
        this.fetchDataList();
      }, 300000);    
    },
    fetchDataList(){
      axios.post('/sickLeaves/list', {
        employee_code: this.employeeData.employee_code,
        month_periode: this.month_periode.monthCode,
        year_periode: this.year_periode.year,
        status: this.statusReff.statusKode,
      }).then(res => {
        this.sickleaves = res.data.data
      })
    },
    getSickLeavesEmployee() {
      //console.log(`kode${this.statusReff.statusKode}`, `bulan${this.year_periode.year}`, `tahun${this.month_periode.monthCode}`)
      this.isSpinner = true,
      this.isLoadingHideButton = false
      axios.post('/sickLeaves/list', {
        employee_code: this.employeeData.employee_code,
        month_periode: this.month_periode.monthCode,
        year_periode: this.year_periode.year,
        status: this.statusReff.statusKode,
      }).then(response => {
          this.ToastDataFound()
          console.log(response.data.data);
          this.sickleaves = response.data.data
        }).catch(error => {
          console.log(error.response.status)
          if (error.response.status === 400) {
            console.log(error)
          }  
          if (error.response.status === 404) {
            this.ToastDataNotFound()
          } 
          if (error.response.status === 401) {
            this.$router.push({ name: "not-authorized" }).catch(() => {});
          } 
          else {
            console.log(error)
          }
      }).finally(() => {
        this.isSpinner = false,
        this.isTableShow = true
        this.isLoadingHideButton = true
      })
    },
    onRowSelected(items) {
      if (this.selected = items[0]){
        this.isDisableByNotSelected = false
        console.log(this.selected);
        if (this.selected.status_text !== 'PROPOSED'){
          this.isDisableByVerified = true
        } 
        else {
          this.isDisableByVerified = false
          this.SickLeavesCode = this.selected.sick_leave_code
          console.log(this.SickLeavesCode);
        } 
        // this.SickLeavesCode = this.selected.sick_leave_code
        // this.isDisableByVerified = false
      } else {
        this.$refs.selectableTable.clearSelected()
        this.isDisableByVerified = true
        this.isDisableByNotSelected = true
      }
      // this.selected = items[0]
      // console.log(this.selected);
      // if (this.selected.status_text !== 'OPEN'){
      //   this.isDisableByVerified = true
      // } 
      // else {
      //  this.isDisableByVerified = false
      //  this.SickLeavesCode = this.selected.sick_leave_code
      //   console.log(this.SickLeavesCode);
      // }
    },
    viewSelected() {
      //const sickCode = this.selected.sick_leave_code
      axios.post('/sickLeaves/detil', {
        leave_code: this.SickLeavesCode
      })
      .then(response => {
        window.open(response.data.data.file_dir, '_blank', "height=900,width=900");
        console.log(response.data);
      })
    },
    onClickUpdate(){
      this.$root.$emit('bv::toggle::collapse', 'sidebar-right-update')
    },
    confirmText() {
      this.$swal({
        title: 'Apa anda yakin?',
        text: "Data yang sudah dibatalkan tidak dapat dikembalikan",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.isTableShow = false
          console.log(this.selected.sick_leave_code);
          axios.patch('/sickLeaves/delete', {
              sick_leave_code: this.SickLeavesCode
          })
          .then(() => {
            this.$swal({
              icon: 'success',
              title: 'Canceled!',
              text: 'Cuti anda berhasil dibatalkan.',
              timer: 1500,
            })
            this.resetSelected()
          })
          .catch(error => {
            console.log(error.response.data.message);
          })
          .finally(() => {
            this.isTableShow = true
            this.getSickLeavesEmployee()
          })
        }
      })
    },
    ToastDataNotFound() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Data tidak ditemukan.',
          icon: 'BellIcon',
          variant: 'danger',
        },
      })
    },
    ToastDataFound() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Data ditemukan.',
          icon: 'BellIcon',
          variant: 'success',
        },
      })
    },
  // validationFormInfo() {
  //   this.$refs.infoRules.validate().then(success => {
  //     if (success) {
  //       this.getLeaveEmployee()
  //     } else {
  //       this.$refs['v-modal'].show()
  //     }
  //   })
  // },
  },
}
</script>

<style lang="scss" scoped>
.btn-scroll-to-top {
  position: fixed;
    bottom: 5%;
    right: 30px;
    z-index: 99;

    // opacity: 0;
    // // transform: translateY(100px);
    // transition: all .5s ease;

    // &.show {
    //   opacity: 1;
    //   // transform: translateY(0)
    // }
}
</style>
